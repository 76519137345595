import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { toast } from "react-hot-toast";
import {
    associate_provenance_form,
    associate_provenance_form_fields,
} from "../pages/Provenance/config/formdata";
import { data } from "../pages/ProvenanceDetails/config/data";
import { GET_PROVENANCE_TABLE } from "../pages/Provenance/graphql/getProvenanceTable.query";
import { GET_PROVENANCE_DETAILS } from "../pages/ProvenanceDetails/graphql/getProvenanceDetails.query";
import { capitalCase } from "change-case";
import is from "is_js";
import { GET_GTINS } from "../pages/Provenance/graphql/getGtins.query";
import { ASSOCIATE_PROVENANCE } from "../pages/Provenance/graphql/associate.mutation";
import { CHECK_BATCH } from "../pages/Provenance/graphql/checkBatch.query";
import moment from "moment";
import { getErrorMessage } from "../utils/error.message";
import { GET_PROVENANCE_HASH } from "../pages/ProvenanceDetails/graphql/getProvenanceHash.query";
import axios from "axios";
import { StorageModule } from "@vlinder-web/storage-module-react";
import { NO_ASSET } from "../assets/images";
import { GET_PROVENANCE_CREDS } from "../pages/ProvenanceDetails/graphql/getProvenanceCreds.query";
import { GET_PROVENANCE_TEMPLATES } from "../pages/ProvenanceTemplates/graphql/getProvenanceTemplates.query";

const SERVICE_NAME = "provenance.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class ProvenanceService {
    name = SERVICE_NAME;
    private static instance: ProvenanceService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new ProvenanceService();
        }
        return this.instance;
    }

    // _getColumnDefs = (data: any) => {
    //     let _data = data && Array.isArray(data) && data.length > 0 ? data.map((item: any, index: number) => {
    //         if(item?.accessor === 'status'){
    //             return {
    //                 ...item,
    //                 typeProps: {
    //                     ...item?.typeProps,
    //                     edit: {
    //                         icon: "far fa-edit"
    //                     },
    //                 }
    //             }
    //         } else {
    //             return item;
    //         }
    //     }) : [];
    //     return _data;
    // }

    _getAssocaiteFormFields = (data: any) => {
        let _data: any[] =
            data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((item: any, index: number) => {
                return {
                    label: item?.name,
                    value: item?._id,
                };
            });
        if (_data) {
            let formFields =
                associate_provenance_form &&
                associate_provenance_form.map((item: any, index: number) => {
                    if (item?.name === "productId") {
                        return {
                            ...item,
                            fieldProps: {
                                ...item?.fieldProps,
                                initialOptions: _data,
                            },
                        };
                    } else {
                        return { ...item };
                    }
                });
            return formFields;
        }
    };

    async getProvenanceTable(limit: number, skip: number, filter: any) {
        log.blue(
            ` filter : `,
            filter,
            filter?.mfgDate && moment(filter?.mfgDate).format("DD.MM.YYYY")
        );
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PROVENANCE_TABLE,
                    variables: {
                        criteria: {
                            ...filter,
                            product: filter?.product ?? null,
                            batch: filter?.batch ?? null,
                            gtin: filter?.gtin ?? null,
                            provenance: filter?.provenance ?? null,
                            plantCode: filter?.plantCode ?? null,
                            download: filter?.download ?? false,
                            mfgDate: filter?.mfgDate
                                ? moment(filter?.mfgDate).format("DD.MM.YYYY")
                                : null,
                        },
                        skip: skip,
                        limit: limit,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.provenanceTable)
                    return {
                        ...res?.data?.provenanceTable,
                        columnDefs:
                            res?.data?.provenanceTable?.data?.columnDefs,
                        associate_provenance_form: this._getAssocaiteFormFields(
                            res?.data?.getProducts?.data
                        ),
                        associate_provenance_form_fields:
                            associate_provenance_form_fields,
                    };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _gtinValues(data: any) {
        let _data =
            data && Array.isArray(data) && data.length > 0
                ? data.map((item: any, index: number) => {
                      return {
                          ...item,
                          label: item?.gtinKey,
                          value: item?._id,
                      };
                  })
                : [];
        return _data;
    }

    async getGtins(id: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_GTINS,
                    variables: {
                        where: {
                            productId: id,
                        },
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getGtinsByProductID) {
                    return {
                        gtins: this._gtinValues(res?.data?.getGtinsByProductID),
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async associateProvenance(payload: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                log.blue(`in provenance create service payload :`, payload);
                const res = await client.mutate({
                    mutation: ASSOCIATE_PROVENANCE,
                    variables: {
                        ...payload,
                    },
                    fetchPolicy: "no-cache",
                });
                if (res?.data?.updateProvenance) {
                    return res?.data?.updateProvenance;
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    _getSmDetails = (data: any) => {
        let result: any[] = [];
        data &&
            Array.isArray(data) &&
            data.map((item, index) => {
                let _step: any = {};
                _step.level = item?.subtitle.concat(" ", item?.title);
                _step.number = item?.title;
                _step.title =
                    item?.parentCredTx?.credentialTemplate?.name &&
                    capitalCase(item?.parentCredTx?.credentialTemplate?.name);
                let x =
                    item?.parentCredTx?.credentialSubjectKeyVals &&
                    is.array(item?.parentCredTx?.credentialSubjectKeyVals) &&
                    item?.parentCredTx?.credentialSubjectKeyVals.map(
                        (ele: any, eleIndex: number) => {
                            if (ele?.key === "description") {
                                _step.subTitle = ele?.value;
                            }
                        }
                    );
                _step.modalData = item?.parentCredTx?.credentialSubjectKeyVals;
                _step.dat =
                    item?.credTxs &&
                    is.array(item?.credTxs) &&
                    item?.credTxs.map((ele: any, eleIndex: number) => {
                        let _subtitle = "";
                        let z =
                            ele?.credentialSubjectKeyVals &&
                            is.array(ele?.credentialSubjectKeyVals) &&
                            ele?.credentialSubjectKeyVals.map(
                                (ele1: any, ele1Index: number) => {
                                    if (ele1?.key === "description") {
                                        _subtitle = ele1?.value;
                                    }
                                }
                            );
                        return {
                            title:
                                ele?.credentialTemplate?.name &&
                                capitalCase(ele?.credentialTemplate?.name),
                            subTitle: _subtitle,
                            modalData: ele?.credentialSubjectKeyVals,
                        };
                    });
                result.push(_step);
            });
        return result;
    };

    _getProvenanceDetail(data: any) {
        return {
            title: data?.ProvDetailInfo?.title,
            subtitle: data?.ProvStepTitle?.title,
        };
    }

    async getProvenanceTemplates(payload: any) {
        log.info("getProvenanceTemplates");
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PROVENANCE_TEMPLATES,
                    variables: {},
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getProvTemplates) {
                    return res?.data?.getProvTemplates;
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getProvenanceDetail(provenanceId: string) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PROVENANCE_DETAILS,
                    variables: {
                        provenanceId,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getProvenance)
                    return {
                        //...this._getProvenanceDetail(res?.data),
                        provenanceDetail: res?.data?.getProvenance,
                        timeLineData: this._getSmDetails(
                            res?.data?.getProvenance?.provSteps
                        ),
                    };
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async checkProvBatch(payload: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: CHECK_BATCH,
                    variables: {
                        ...payload,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.checkProvForDefaultBatch) {
                    return res?.data?.checkProvForDefaultBatch;
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getProvenanceHash(payload: any) {
        log.info(`provenance hash service payload: `, payload);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PROVENANCE_HASH,
                    variables: {
                        credId: payload?.credId,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getProvsForCredHash) {
                    return res?.data?.getProvsForCredHash;
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getPredecessorData(payload: any) {
        log.blue(`in getPredecessorData service payload :`, payload);
        const storageSrv = StorageModule.getInstance();
        const DEFAULT_APP_CONFIG = storageSrv.get("appConfig", "session");
        const apiURL = "https://kiran.vlinderserver.online/trag";
        const provenanceEP = "/provenance/step_cred_bulk/no_auth";
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: GET_PROVENANCE_CREDS,
                    variables: {
                        gtin: payload?.gtinKey,
                        credTxIds: payload?.predecessorIds,
                        provId: payload?.provId,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data) {
                    log.info(`getPredecessorData response: `, res);

                    return {
                        ...res,
                        predecessorData: this._getPredecessorData(
                            res?.data?.getProvStepCreds
                        ),
                    };
                }
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private _getPredecessorData(data: any) {
        let _res: any = [];
        data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((item: any, index: number) => {
                _res.push({
                    title: item?.ProvDetailInfo?.title,
                    subtitle: item?.ProvDetailInfo?.aboutProvInfo,
                    meta: this._getMeta(item?.ProvDetailInfo?.provInfoList),
                    defaultCenter: this._getDefaultCenter(
                        item?.provMapProps?.geojson
                    ),
                    defaultZoom: 12,
                    marker: this._getMarkers(item?.provMapProps?.geojson),
                    images: this._getImages(item?.ProvStepImages?.provImages),
                });
            });
        console.log(`_getPredecessorData :`, _res);
        return _res;
    }

    private _getMeta(data: any) {
        let res: any[] =
            data &&
            Array.isArray(data) &&
            data.map((item: any, index: number) => {
                if (
                    item?.label &&
                    camelCase(item?.label) !== "description" &&
                    camelCase(item?.label) !== "geoJson" &&
                    item?.value
                )
                    return {
                        label: item?.label,
                        value: item?.value,
                    };
            });
        return res;
    }

    private _getDefaultCenter(geoJson: any) {
        if (geoJson) {
            let coordinates: any[] =
                geoJson?.features &&
                Array.isArray(geoJson?.features) &&
                geoJson?.features.length > 0
                    ? geoJson?.features[0]?.geometry?.coordinates
                    : [];
            if (coordinates && coordinates.length > 0) {
                let defaultCenter = {
                    lng: coordinates[0],
                    lat: coordinates[1],
                };

                console.log(`defaultCenter in service:`, defaultCenter);
                return defaultCenter;
            }
        }
    }

    private _getMarkers = (geoJson: any) => {
        if (geoJson && Object.keys(geoJson).length > 0) {
            let markers: any = [];
            geoJson?.features &&
                Array.isArray(geoJson?.features) &&
                geoJson?.features.length > 0 &&
                geoJson?.features.forEach((item: any, index: number) => {
                    if (item?.geometry?.type === "Point") {
                        item?.geometry?.coordinates &&
                            Array.isArray(item?.geometry?.coordinates) &&
                            item?.geometry?.coordinates.length > 0 &&
                            markers.push({
                                lng: item?.geometry?.coordinates[0],
                                lat: item?.geometry?.coordinates[1],
                            });
                    }
                });
            console.log(`geoJson in service:`, markers);
            return markers;
        }
    };

    private _getImages(images: any[]) {
        let _res: any;
        images &&
            Array.isArray(images) &&
            images.length > 0 &&
            images.map((item: any, index: number) => {
                if (typeof item === "string")
                    _res.push({
                        original: item,
                        thumbnail: item,
                        thumbnailHeight: "70",
                        thumbnailClass: "image-thumb",
                    });
            });
        return _res;
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
