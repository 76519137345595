const theme = {
    primary_color: "#1a4677",
    secondary_color: "#fe80b2",
    tertiary_color: "#7e37d8",
    color4: "#5987af",
    color5: "#88aecb",
    light_color: "#DCE6EC",
    color6: "#59C4D6",
    color7: "#f08b2e",
    color8: "#D83737",
    color9: "#e2e86f",
    color10: "#00a76d",
};

export const RATINGS_DATA = [
    {
        x: "Excellent",
        y: 0,
    },
    {
        x: "Very Good",
        y: 0,
    },
    {
        x: "Good",
        y: 0,
    },
    {
        x: "Fair",
        y: 0,
    },
    {
        x: "Poor",
        y: 0,
    },
];

export const PROVENACE_DATA = [
    {
        x: "1006",
        y: 62,
    },
    {
        x: "1022",
        y: 104,
    },
    {
        x: "1023",
        y: 50,
    },
    {
        x: "1054",
        y: 40,
    },
    {
        x: "1240",
        y: 505,
    },
];

export const INDIA_DATA = [
    {
        x: "Jan",
        y: 800,
    },
    {
        x: "Feb",
        y: 300,
    },
    {
        x: "Mar",
        y: 900,
    },
    {
        x: "Apr",
        y: 100,
    },
];
export const US_DATA = [
    {
        x: "Jan",
        y: 50,
    },
    {
        x: "Feb",
        y: 10,
    },
    {
        x: "Mar",
        y: 40,
    },
    {
        x: "Apr",
        y: 150,
    },
];
export const AUS_DATA = [
    {
        x: "Jan",
        y: 80,
    },
    {
        x: "Feb",
        y: 20,
    },
    {
        x: "Mar",
        y: 50,
    },
    {
        x: "Apr",
        y: 40,
    },
];
export const GBR_DATA = [
    {
        x: "Jan",
        y: 10,
    },
    {
        x: "Feb",
        y: 30,
    },
    {
        x: "Mar",
        y: 50,
    },
    {
        x: "Apr",
        y: 2,
    },
];

export const FEEDBACKS_DATA = [
    {
        x: "Jan",
        y: 30,
    },
    {
        x: "Feb",
        y: 50,
    },
    {
        x: "Mar",
        y: 5,
    },
    {
        x: "Apr",
        y: 6,
    },
];

export const PRODUCTS_DATA = [
    {
        x: "Jan",
        y: 1,
    },
    {
        x: "Feb",
        y: 2,
    },
    {
        x: "Mar",
        y: 2,
    },
    {
        x: "Apr",
        y: 4,
    },
];

export const ENROLMENTS_DATA = [
    {
        x: "Jan",
        y: 40,
    },
    {
        x: "Feb",
        y: 27,
    },
    {
        x: "Mar",
        y: 12,
    },
    {
        x: "Apr",
        y: 17,
    },
];

export const DISPUTED_CRED_DATA = [
    {
        x: "Jan",
        y: 200,
    },
    {
        x: "Feb",
        y: 30,
    },
    {
        x: "Mar",
        y: 90,
    },
    {
        x: "Apr",
        y: 76,
    },
    {
        x: "May",
        y: 98,
    },
    {
        x: "Jun",
        y: 11,
    },
    {
        x: "July",
        y: 29,
    },
];

export const DATA = [
    {
        x: "Jan",
        y: 2300,
    },
    {
        x: "Feb",
        y: 2000,
    },
    {
        x: "Mar",
        y: 1000,
    },
    {
        x: "Apr",
        y: 218,
    },
];

export const TIMELINE_DATA = [
    {
        x: new Date("2021-01-01").getTime(),
        y: 100,
    },
    {
        x: new Date("2021-02-01").getTime(),
        y: 22,
    },
    {
        x: new Date("2021-03-01").getTime(),
        y: 35,
    },
    {
        x: new Date("2021-04-01").getTime(),
        y: 125,
    },
    {
        x: new Date("2021-05-01").getTime(),
        y: 180,
    },
    {
        x: new Date("2021-06-01").getTime(),
        y: 200,
    },
    {
        x: new Date("2021-07-01").getTime(),
        y: 58,
    },
    {
        x: new Date("2021-08-01").getTime(),
        y: 21,
    },
    {
        x: new Date("2021-09-01").getTime(),
        y: 98,
    },
    {
        x: new Date("2021-10-01").getTime(),
        y: 190,
    },
    {
        x: new Date("2021-11-01").getTime(),
        y: 200,
    },
    {
        x: new Date("2021-12-01").getTime(),
        y: 76,
    },
];

export const LAYOUT = [
    // {i: 'a', x: 0, y: 0, w: 1, h: 2, },
    { i: "p", x: 0, y: 0, w: 12, h: 8 },
    { i: "q", x: 0, y: 1, w: 12, h: 8 },
    { i: "r", x: 0, y: 2, w: 12, h: 8 },
    { i: "s", x: 0, y: 3, w: 12, h: 8 },
];

export const LAYOUTSM = [
    // {i: 'a', x: 0, y: 0, w: 1, h: 2, },
    { i: "empty-1", x: 0, y: 0, w: 1, h: 0 },
    { i: "k", x: 1, y: 0, w: 12, h: 2 },
    { i: "empty-1", x: 0, y: 0, w: 1, h: 0 },
    { i: "l", x: 1, y: 0, w: 12, h: 2 },
    { i: "empty-1", x: 0, y: 0, w: 1, h: 0 },
    { i: "m", x: 1, y: 0, w: 12, h: 2 },
    { i: "empty-1", x: 0, y: 0, w: 1, h: 0 },
    { i: "x", x: 1, y: 0, w: 12, h: 2 },
    { i: "empty-1", x: 0, y: 0, w: 1, h: 0 },
    { i: "y", x: 1, y: 0, w: 12, h: 2 },
    { i: "empty-2", x: 11, y: 0, w: 1, h: 0 },
    { i: "empty-1", x: 0, y: 3, w: 1.5, h: 3 },
    { i: "1", x: 1.5, y: 3, w: 3, h: 3 },
    { i: "2", x: 4.5, y: 3, w: 3, h: 3 },
    { i: "3", x: 7.5, y: 3, w: 3, h: 30 },
    { i: "empty-2", x: 10.5, y: 3, w: 1.5, h: 3 },
    { i: "a", x: 0, y: 4, w: 12, h: 6 },
    { i: "b", x: 0, y: 5, w: 12, h: 6 },
    { i: "c", x: 0, y: 6, w: 12, h: 6 },
    { i: "d", x: 0, y: 7, w: 12, h: 6 },
    { i: "e", x: 0, y: 8, w: 12, h: 6 },
    { i: "f", x: 0, y: 9, w: 12, h: 6 },
    { i: "g", x: 0, y: 10, w: 12, h: 5 },
    { i: "h", x: 0, y: 11, w: 12, h: 5 },
    { i: "i", x: 0, y: 3, w: 12, h: 6 },
    { i: "j", x: 0, y: 1, w: 12, h: 6 },
    { i: "n", x: 0, y: 2, w: 12, h: 6 },
];

export const GRID_ITEMS = [
    {
        key: "empty-1",
        backgroundColor: "transparent",
        type: "placeholder",
        typeProps: {
            backgroundColor: "transparent",
        },
    },
    {
        key: "empty-2",
        backgroundColor: "transparent",
        type: "placeholder",
        typeProps: {
            backgroundColor: "transparent",
        },
    },

    {
        key: "p",
        backgroundColor: "transparent",
        type: "column-chart",
        typeProps: {
            options: {
                chart: {
                    background: "transparent",
                    // foreColor: '#fff',
                },
                colors: [
                    theme.color6,
                    theme.secondary_color,
                    theme.tertiary_color,
                ],
                markers: {
                    size: 0,
                },
                fill: {
                    type: "solid",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100],
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        // columnWidth: '55%',
                        endingShape: "rounded",
                        dataLabels: {
                            position: "top",
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ["#000"],
                    },
                    offsetY: -25,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    type: "category",
                },
            },
            series: [
                {
                    name: "Provenance",
                    data: PROVENACE_DATA,
                },
            ],
            headerProps: {
                title: "Trag Interface : Success API calls",
                titleType: "title3",
                titleStyle: {
                    color: "#000",
                    fontWeight: "bold",
                },
            },
            cardStyle: {},
            cardHeaderStyle: {
                backgroundColor: "#d2d2d2",
            },
            footerProps: {
                cardFooterStyle: {
                    backgroundColor: "transparent",
                },
                title: "",
                titleType: "footnote",
                titleStyle: {
                    textAlign: "right",
                },
            },
        },
    },
    {
        key: "q",
        backgroundColor: "transparent",
        type: "column-chart",
        typeProps: {
            options: {
                chart: {
                    background: "transparent",
                    // foreColor: '#fff',
                },
                colors: [
                    theme.color8,
                    theme.secondary_color,
                    theme.tertiary_color,
                ],
                markers: {
                    size: 0,
                },
                fill: {
                    type: "solid",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100],
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        // columnWidth: '55%',
                        endingShape: "rounded",
                        dataLabels: {
                            position: "top",
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ["#000"],
                    },
                    offsetY: -25,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    type: "category",
                },
            },
            series: [
                {
                    name: "Provenance",
                    data: PROVENACE_DATA,
                },
            ],
            headerProps: {
                title: "Trag Interface : Failure API calls",
                titleType: "title3",
                titleStyle: {
                    color: "#000",
                    fontWeight: "bold",
                },
            },
            cardStyle: {},
            cardHeaderStyle: {
                backgroundColor: "#d2d2d2",
            },
            footerProps: {
                cardFooterStyle: {
                    backgroundColor: "transparent",
                },
                title: "",
                titleType: "footnote",
                titleStyle: {
                    textAlign: "right",
                },
            },
        },
    },
    {
        key: "r",
        backgroundColor: "transparent",
        type: "stacked-column-chart",
        typeProps: {
            options: {
                chart: {
                    background: "transparent",
                    // foreColor: '#fff',
                    stacked: true,
                },
                colors: [theme.color10, theme.color7, theme.color9],
                markers: {
                    size: 0,
                },
                fill: {
                    type: "solid",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100],
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            total: {
                                position: "top",
                                enabled: true,
                                style: {
                                    fontSize: "13px",
                                    fontWeight: 900,
                                    color: "#000000",
                                },
                            },
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ["#000"],
                    },
                    offsetY: -25,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    type: "category",
                },
            },
            series: [
                {
                    name: "PRODUCT A",
                    data: [44, 55, 41, 67, 22, 43],
                },
                {
                    name: "PRODUCT B",
                    data: [13, 23, 20, 8, 13, 27],
                },
                {
                    name: "PRODUCT C",
                    data: [11, 17, 15, 15, 21, 14],
                },
                {
                    name: "PRODUCT D",
                    data: [21, 7, 25, 13, 22, 8],
                },
            ],
            headerProps: {
                title: "Dispatch Interface : API Calls",
                titleType: "title3",
                titleStyle: {
                    color: "#000",
                    fontWeight: "bold",
                },
            },
            cardStyle: {},
            cardHeaderStyle: {
                backgroundColor: "#d2d2d2",
            },
            footerProps: {
                cardFooterStyle: {
                    backgroundColor: "transparent",
                },
                title: "",
                titleType: "footnote",
                titleStyle: {
                    textAlign: "right",
                },
            },
        },
    },
    {
        key: "s",
        backgroundColor: "transparent",
        type: "column-chart",
        typeProps: {
            options: {
                chart: {
                    background: "transparent",
                    // foreColor: '#fff',
                },
                colors: [
                    theme.color6,
                    theme.secondary_color,
                    theme.tertiary_color,
                ],
                markers: {
                    size: 0,
                },
                fill: {
                    type: "solid",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100],
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        // columnWidth: '55%',
                        endingShape: "rounded",
                        dataLabels: {
                            position: "top",
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ["#000"],
                    },
                    offsetY: -25,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    type: "category",
                },
            },
            series: [
                {
                    name: "Provenance",
                    data: PROVENACE_DATA,
                },
            ],
            headerProps: {
                title: "Dispatch Interface : Total Objects",
                titleType: "title3",
                titleStyle: {
                    color: "#000",
                    fontWeight: "bold",
                },
            },
            cardStyle: {},
            cardHeaderStyle: {
                backgroundColor: "#d2d2d2",
            },
            footerProps: {
                cardFooterStyle: {
                    backgroundColor: "transparent",
                },
                title: "",
                titleType: "footnote",
                titleStyle: {
                    textAlign: "right",
                },
            },
        },
    },
];
