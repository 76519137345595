export const AdminAccess = [
    "/",
    "/faq",
    "/products",
    "/products/detail",
    "/gtins/detail",
    "/batches/detail",
    "/transactions",
    "/transactions/brandprotection",
    "/transactions/wb-authenticity-scans",
    "/feedback",
    "/provenance",
    "/provenance/detail",
    "/personas",
    "/personas/template",
    "/personas/table",
    "/personas/new-person",
    "/sap-analytics",
    "/master-dispatch",
    "/report",
    "/analytics/business",
    "/analytics/technical",
    "/users-table",
    "/new-user",
    "/manufacturer",
    "/manufacturer/social-handles",
    "/create-qr-codes",
    "/admin-level-qr",
    "/new-assortment-range",
    "/assortment-ranges",
    "/assortment-batches",
    "/dispatch-table",
    "/club-registration",
];
