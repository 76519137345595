export const product_form = [
    {
        label: "Product Name (Displayed in the Label) *",
        name: "productName",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
    {
        label: "Upload product images(recommended: 768x1024) ",
        name: "productImages",
        type: "image-picker",
        tooltip: "Select images",
        fieldProps: {
            fileType: "image",
            noOfImages: 3,
            docType: ["image/jpeg", "image/png", "image/jpg"],
            buttonTitle: "Please Select Images",
            // buttonColor: "#2664f5",
            buttonColor: "transparent",
            buttonContainerStyle: {},
            allowMultipleSelect: true,
            buttonTitleStyle: {
                color: "#000000",
            },
            icon: {
                name: "upload",
                type: "font-awesome",
                size: 20,
                color: "#000000",
            },
        },
    },
    {
        label: "Description *",
        name: "description",
        type: "ck-editor",
        fieldProps: {},
    },
    {
        label: "Product Video URL(Max upto 5, comma separated)",
        name: "video",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
    // {
    //     label: "Key",
    //     name: "key1",
    //     type: "dynamic-field",
    //     fieldProps: {
    //         defaultValue: {
    //             key: "key",
    //             value: "",
    //         },
    //     },
    // },
];

export const product_form_fields = {
    productName: {
        validators: {
            notEmpty: {
                message: "Product name is required",
            },
        },
    },
    description: {
        validators: {
            notEmpty: {
                message: "Product description is required",
            },
        },
    },
    productImages: {
        validators: {
            notEmpty: {
                message: "Product image is required",
            },
        },
    },
};

export const gtin_form = [
    // {
    //     label: "Name *",
    //     name: "gtinName",
    //     type: "text-input",
    //     fieldProps: {
    //         defaultValue: "",
    //     },
    // },
    {
        label: "Barcode (GTIN No. - Can not be edited later) *",
        name: "gtinKey",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
    {
        label: "Select Product *",
        name: "productId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
    {
        label: "Description",
        name: "description",
        type: "text-area",
        fieldProps: {},
    },
    {
        label: "Key",
        name: "key1",
        type: "dynamic-field",
        fieldProps: {
            defaultValue: {},
        },
    },
];

export const gtin_form_fields = {
    // gtinName: {
    //     validators: {
    //         notEmpty: {
    //             message: "Name is required",
    //         },
    //     },
    // },
    productId: {
        validators: {
            notEmpty: {
                message: "Product need to be selected",
            },
        },
    },
    gtinKey: {
        validators: {
            notEmpty: {
                message: "Barcode (GTIN No.) is required",
            },
        },
    },
};

export const batch_form = [
    {
        label: "Batch No. (Can not be edited later) *",
        name: "batchName",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
    {
        label: "Select Product *",
        name: "productId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
    {
        label: "Select Barcode *",
        name: "gtinId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
    {
        label: "Description ",
        name: "description1",
        type: "text-area",
        fieldProps: {},
    },
    // {
    //     label: "Key",
    //     name: "key1",
    //     type: "dynamic-field",
    //     fieldProps: {
    //         defaultValue: {
    //             key:"hi",
    //             value:"hello"
    //         }
    //     },
    // },
];

export const batch_form_fields = {
    batchName: {
        validators: {
            notEmpty: {
                message: "Name is required",
            },
        },
    },
    productId: {
        validators: {
            notEmpty: {
                message: "Product need to be selected",
            },
        },
    },
    gtinId: {
        validators: {
            notEmpty: {
                message: "Barcode is required",
            },
        },
    },
};

export const provenance_form = [
    {
        label: "Name *",
        name: "provenanceName",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
    {
        label: "Select Template *",
        name: "template",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
];

export const provenance_form_fields = {
    provenanceName: {
        validators: {
            notEmpty: {
                message: "Provenancce name is required",
            },
        },
    },
    template: {
        validators: {
            notEmpty: {
                message: "Template is required",
            },
        },
    },
};

export const STEP_FORM = [
    {
        label: "Title *",
        name: "title",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
    {
        label: "Description *",
        name: "description",
        type: "text-area",
        fieldProps: {
            defaultValue: "",
        },
    },
    {
        label: "Latitude",
        name: "title",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
    {
        label: "Longitude",
        name: "title",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
    {
        label: "Key",
        name: "key1",
        type: "dynamic-field",
        keyFieldProps: {},
    },
];

export const STEP_FORM_FIELDS = {
    title: {
        validators: {
            notEmpty: {
                message: "Title is required",
            },
        },
    },
    description: {
        validators: {
            notEmpty: {
                message: "Description is required",
            },
        },
    },
};

export const associate_provenance_form = [
    {
        label: "Template *",
        name: "templateId",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
            disabled: true,
        },
    },
    {
        label: "Provenance Name *",
        name: "provName",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
            disabled: true,
        },
    },
    {
        label: "Select Product *",
        name: "productId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
    {
        label: "Select GTIN *",
        name: "gtinId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
    {
        label: "Select Batch *",
        name: "batchId",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
];

export const associate_provenance_form_fields = {
    productId: {
        validators: {
            notEmpty: {
                message: "Product need to be selected",
            },
        },
    },
    gtinId: {
        validators: {
            notEmpty: {
                message: "GTIN is required",
            },
        },
    },
    batchId: {
        validators: {
            notEmpty: {
                message: "Batch is required",
            },
        },
    },
};
