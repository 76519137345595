export const product_choose = [
    {
        key: "existingProduct",
        title: "Existing Product",
    },
    {
        key: "newProduct",
        title: "New Product",
    },
];

export const product_form = [
    {
        label: "Product Name (Displayed in the Label) *",
        name: "productName",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
    {
        label: "Upload product images(recommended: 768x1024) ",
        name: "productImages",
        type: "image-dropzone",
        required: true,
        values: [],
        maxFiles: 3,
        fieldProps: {},
    },
    {
        label: "Description *",
        name: "description",
        type: "ck-editor",
        fieldProps: {},
    },
    {
        label: "Product Video URL(Max upto 5, comma separated)",
        name: "video",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
];

export const product_form_fields = {
    productName: {
        validators: {
            notEmpty: {
                message: "Product name is required",
            },
        },
    },
    description: {
        validators: {
            notEmpty: {
                message: "Product description is required",
            },
        },
    },
    productImages: {
        validators: {
            notEmpty: {
                message: "Product image is required",
            },
        },
    },
};

export const variant_choose = [
    {
        key: "existingVariant",
        title: "Existing Variant",
    },
    {
        key: "newVariant",
        title: "New Variant",
    },
];

export const gtin_form = [
    {
        label: "Barcode (GTIN No. - Can not be edited later) *",
        name: "gtinKey",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
    {
        label: "Generate a Vlinder barcode",
        name: "generateGtin",
        type: "link",
    },
    {
        label: "Description",
        name: "description",
        type: "text-area",
        fieldProps: {},
    },
    {
        label: "Key",
        name: "key1",
        type: "dynamic-field",

        fieldProps: {
            defaultValue: {},
        },
    },
];

export const gtin_form_fields = {
    gtinKey: {
        validators: {
            notEmpty: {
                message: "Barcode (GTIN No.) is required",
            },
        },
    },
};

export const batch_choose = [
    {
        key: "existingBatch",
        title: "Existing Batch",
    },
    {
        key: "newBatch",
        title: "New Batch",
    },
];

export const batch_form = [
    {
        label: "Batch No. (Can not be edited later) *",
        name: "batchName",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
    {
        label: "Description ",
        name: "description1",
        type: "text-area",
        fieldProps: {},
    },
];

export const batch_form_fields = {
    batchName: {
        validators: {
            notEmpty: {
                message: "Name is required",
            },
        },
    },
};

export const provenance_form = [
    {
        label: "Provenance Name",
        name: "provenanceName",
        type: "text-input",
        fieldProps: { defaultValue: "" },
    },
    {
        label: "Select Template",
        name: "SelectTemplate",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [],
        },
    },
];

export const provenance_form_fields = {
    provenanceName: {
        validators: {
            notEmpty: {
                message: "Name is required",
            },
        },
    },
    SelectTemplate: {
        validators: {
            notEmpty: {
                message: "Template is required",
            },
        },
    },
};
