export const DATA = {
    img: "https://s3.ap-south-1.amazonaws.com/io.vlinder.public/batch.png",
    title: "Batch 1",
    description: "Description....",
    meta: [
        {
            key: "GTIN",
            value: "8901193503373",
        },
        {
            key: "Contest Button Text",
            value: "Feedback",
        },
        {
            key: "Promo Button Text",
            value: "Visit Amrut",
        },
    ],
    buttons: [
        {
            key: "edit",
            title: "Edit Batch",
        },
        {
            key: "editShelfLife",
            title: "Edit ShelfLife",
        },
        {
            key: "add",
            title: "Create Unique Item",
        },
    ],
};

export const BATCHDATA = [
    {
        item: {
            idx: 1,
            name: "Batch 1",
            img: "https://s3.ap-south-1.amazonaws.com/io.vlinder.public/batch.png",
            price: 100,
            category: "Batches",
        },
    },
    {
        item: {
            idx: 2,
            name: "Batch 2",
            img: "https://s3.ap-south-1.amazonaws.com/io.vlinder.public/batch.png",
            price: 200,
            category: "Batches",
        },
    },
    {
        item: {
            idx: 3,
            name: "Batch 3",
            img: "https://s3.ap-south-1.amazonaws.com/io.vlinder.public/batch.png",
            price: 1000,
            category: "Batches",
        },
    },
];

export const BUTTONS = [
    // {
    //   key: "edit",
    //   title: "Edit Batch",
    // },
    {
        key: "edit",
        title: "Edit Brand Protection Window",
    },
];

export const FIELDS: any = {
    validFrom: {
        validators: {
            notEmpty: {
                message: "Valid from date need to be selected",
            },
        },
    },

    validUntil: {
        validators: {
            notEmpty: {
                message: "Valid untill date need to be selected",
            },
        },
    },
};

export const FORM_DATA = [
    {
        label: "Valid From",
        name: "validFrom",
        type: "date-picker",
        fieldProps: {},
    },
    {
        label: "Valid Until",
        name: "validUntil",
        type: "date-picker",
        fieldProps: {},
    },
    {
        label: "Description",
        name: "description",
        type: "text-area",
        fieldProps: {},
    },
];

// export const FORM_DATA = [
//   {
//     name: "step 1",
//     compProps: [
//       {
//         type: "date-time",
//         widgetProps: {
//           label: "Valid From",
//           name: "validFrom",
//           iso: true,
//         },
//         errorText: {
//           required: "Batch Shelf Valid From date is required",
//         },
//         validFeedback: "Looks Good !",
//       },
//       {
//         type: "date-time",
//         widgetProps: {
//           label: "Valid Until",
//           name: "validUntil",
//           iso: true,
//         },
//         errorText: {
//           required: "Batch Shelf Valid Until date is required",
//         },
//         validFeedback: "Looks Good !",
//       },
//     ],
//   },
// ];

export const BATCH_OMIT_DATA = [
    "_id",
    "description",
    "__typename",
    "batches",
    "product",
    "variants",
    "promoVideoUrl",
    "promoWebsiteUrl",
    "promoButtonText1",
    "promoButtonText2",
    "createdAt",
    "creatorUser",
];
