import gql from "graphql-tag";

export const GET_PROVENANCE_TEMPLATES = gql`
    query GetProvTemplates {
        getProvTemplates {
            _id
            name
            type
            provSteps {
                credTxs {
                    credentialContent {
                        credentialSubject
                        evidences
                        geoJSON
                        images
                    }
                    credentialTemplate
                }
                parentCredTx {
                    credentialContent {
                        evidences
                        credentialSubject
                        geoJSON
                        images
                    }
                    credentialTemplate
                }
                title
                subtitle
            }
            updatedAt
            createdAt
        }
    }
`;
