import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { tableData } from "../pages/TransactionsTable/config/table";
import { toast } from "react-hot-toast";
import { tableData as BrandTableData } from "../pages/TransactionsBrandTable/config/table";
import { GET_TRANSACTION_TABLE } from "../pages/TransactionsTable/graphql/getTransactionTable.query";
import { GET_TRANSACTION_BRAND_TABLE } from "../pages/TransactionsBrandTable/graphql/getTransactionBrandTable.query";
import { WB_SCAN_LOGS } from "../pages/DiageoProductWBTransaction/graphql/get-feedback-table.query";
import moment from "moment";
import { getErrorMessage } from "../utils/error.message";

const SERVICE_NAME = "analytics.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class TransactionService {
    name = SERVICE_NAME;
    private static instance: TransactionService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new TransactionService();
        }
        return this.instance;
    }

    async getTransactionTable(limit: number, skip: number, filter: any) {
        log.info(`in transaction table service:`, limit, skip);
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        log.info("filter :", filter);
        let _date = filter?.time ?? [];
        let startDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[0] : null;
        let endDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[1] : null;
        log.info("startDate filter:", startDate, "endDate :", endDate);
        startDate = startDate && moment(startDate).utc().startOf("day");
        startDate = startDate && moment(startDate).add("days", 1);
        startDate = startDate && moment(startDate).subtract(330, "minute");
        startDate = startDate && moment(startDate).utc().format();
        // startDate =
        //     startDate &&
        //     moment(startDate).utc().startOf("day").add("days", 1).format();
        endDate = endDate && moment(endDate).utc().endOf("day");
        endDate = endDate && moment(endDate).add("days", 1);
        endDate = endDate && moment(endDate).subtract(330, "minute");
        endDate = endDate && moment(endDate).utc().format();
        // endDate =
        //     endDate &&
        //     moment(endDate).utc().endOf("day").add("days", 1).format();
        log.info("startDate filter effect:", startDate, "endDate :", endDate);
        try {
            if (client) {
                const res = await client.query({
                    query: GET_TRANSACTION_TABLE,
                    variables: {
                        criteria: {
                            onlyBrandProtectionValid: true,
                            product: filter?.product ?? null,
                            batch: filter?.batch ?? null,
                            email: filter?.email ?? null,
                            device: filter?.device ?? null,
                            interface: filter?.interface ?? null,
                            city: filter?.city ?? null,
                            state: filter?.state ?? null,
                            country: filter?.country ?? null,
                            startDate:
                                filter?.time &&
                                Array.isArray(filter?.time) &&
                                filter?.time[0] !== ""
                                    ? startDate
                                    : null,
                            endDate:
                                filter?.time &&
                                Array.isArray(filter?.time) &&
                                filter?.time[1] !== ""
                                    ? endDate
                                    : null,
                            download: filter?.download ?? false,
                        },
                        limit: limit,
                        skip: skip,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.scanLogTable) return res?.data?.scanLogTable;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getBrandTable(limit: number, skip: number, filter: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        log.info("filter :", filter);
        let _date = filter?.time ?? [];
        let startDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[0] : null;
        let endDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[1] : null;
        log.info("startDate filter:", startDate, "endDate :", endDate);
        startDate = startDate && moment(startDate).utc().startOf("day");
        startDate = startDate && moment(startDate).add("days", 1);
        startDate = startDate && moment(startDate).subtract(330, "minute");
        startDate = startDate && moment(startDate).utc().format();
        // startDate =
        //     startDate &&
        //     moment(startDate).utc().startOf("day").add("days", 1).format();
        endDate = endDate && moment(endDate).utc().endOf("day");
        endDate = endDate && moment(endDate).add("days", 1);
        endDate = endDate && moment(endDate).subtract(330, "minute");
        endDate = endDate && moment(endDate).utc().format();
        // endDate =
        //     endDate &&
        //     moment(endDate).utc().endOf("day").add("days", 1).format();
        log.info("startDate filter effect:", startDate, "endDate :", endDate);
        try {
            if (client) {
                const res = await client.query({
                    query: GET_TRANSACTION_BRAND_TABLE,
                    variables: {
                        criteria: {
                            onlyBrandProtectionValid: false,
                            product: filter?.product ?? null,
                            batch: filter?.batch ?? null,
                            email: filter?.email ?? null,
                            device: filter?.device ?? null,
                            interface: filter?.interface ?? null,
                            city: filter?.city ?? null,
                            state: filter?.state ?? null,
                            country: filter?.country ?? null,
                            startDate:
                                filter?.time &&
                                Array.isArray(filter?.time) &&
                                filter?.time[0] !== ""
                                    ? startDate
                                    : null,
                            endDate:
                                filter?.time &&
                                Array.isArray(filter?.time) &&
                                filter?.time[1] !== ""
                                    ? endDate
                                    : null,
                            download: filter?.download ?? false,
                        },
                        limit: limit,
                        skip: skip,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.scanLogTable) return res?.data?.scanLogTable;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getDiageoProductWBTransaction(
        limit: number,
        skip: number,
        filter: any
    ) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: WB_SCAN_LOGS,
                    variables: {
                        skip: skip,
                        limit: limit,
                        criteria: {
                            download: filter?.download ?? false,
                            name: filter?.name ? filter?.name : null,
                            product: filter?.name ? filter?.product : null,
                            batch: filter?.name ? filter?.batch : null,
                        },
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.wbExciseScanlogTable)
                    return res?.data?.wbExciseScanlogTable;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
