import gql from "graphql-tag";

export const GENERATEGTIN = gql`
    query GenerateGtin {
        generateGtin {
            msg
            newGtin
        }
    }
`;
