import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { toast } from "react-hot-toast";
import { SAP_TABLE } from "../pages/Sap/graphql/sap-table.query";
import { SAP_VIEW } from "../pages/Sap/graphql/sap-view.query";
import moment from "moment";
import { getErrorMessage } from "../utils/error.message";
import { MASTER_DISPATCH_TABLE } from "../pages/MasterDispatchAnalytics/graphql/master-dispatch-table.query";
import { DISPATCH_TABLE } from "../pages/DispatchAnalytics/graphql/dispatch-table.query";
import { GET_DISPATCH_DATA } from "../pages/MasterDispatchAnalytics/graphql/dispatch-data.query";

const SERVICE_NAME = "sap.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class SapService {
    name = SERVICE_NAME;
    private static instance: SapService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new SapService();
        }
        return this.instance;
    }

    async getSapTable(limit: number, skip: number, filter: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        log.info("filter :", filter);
        let _date = filter?.date ?? [];
        let startDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[0] : null;
        let endDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[1] : null;
        log.info("startDate filter:", startDate, "endDate :", endDate);
        startDate = startDate && moment(startDate).utc().startOf("day");
        startDate = startDate && moment(startDate).add("days", 1);
        startDate = startDate && moment(startDate).subtract(330, "minute");
        startDate = startDate && moment(startDate).utc().format();
        // startDate =
        //     startDate &&
        //     moment(startDate).utc().startOf("day").add("days", 1).format();
        endDate = endDate && moment(endDate).utc().endOf("day");
        endDate = endDate && moment(endDate).add("days", 1);
        endDate = endDate && moment(endDate).subtract(330, "minute");
        endDate = endDate && moment(endDate).utc().format();
        // endDate =
        //     endDate &&
        //     moment(endDate).utc().endOf("day").add("days", 1).format();
        log.info("startDate filter effect:", startDate, "endDate :", endDate);
        try {
            if (client) {
                const res = await client.query({
                    query: SAP_TABLE,
                    variables: {
                        skip: skip,
                        limit: limit,
                        criteria: {
                            unit: filter?.unit ?? null,
                            product: filter?.product ?? null,
                            gtin: filter?.gtin ?? null,
                            batch: filter?.batch ?? null,
                            fileName: filter?.fileName ?? null,
                            status: filter?.status ?? null,
                            startDate:
                                filter?.date &&
                                Array.isArray(filter?.date) &&
                                filter?.date[0] !== ""
                                    ? startDate
                                    : null,
                            endDate:
                                filter?.date &&
                                Array.isArray(filter?.date) &&
                                filter?.date[1] !== ""
                                    ? endDate
                                    : null,
                            download: filter?.download ?? false,
                        },
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.sapTable) return res?.data?.sapTable;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    // _getSapView = (id: string, data: any) => {
    //     let res ;
    //     data && Array.isArray(data) && data.length > 0 && data.forEach((item: any, index: number) => {
    //         if(item?._id === id){
    //             res = item;
    //         }
    //     });
    //     return res;
    // }

    async getSapView(payload: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.query({
                    query: SAP_VIEW,
                    variables: {
                        ...payload,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getTNTDump) return res?.data?.getTNTDump;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getMasterDispatchTable(limit: number, skip: number, filter: any) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        log.info("filter :", filter);
        let _date = filter?.date ?? [];
        let startDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[0] : null;
        let endDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[1] : null;
        log.info("startDate filter:", startDate, "endDate :", endDate);
        startDate = startDate && moment(startDate).utc().startOf("day");
        startDate = startDate && moment(startDate).add("days", 1);
        startDate = startDate && moment(startDate).subtract(330, "minute");
        startDate = startDate && moment(startDate).utc().format();
        // startDate =
        //     startDate &&
        //     moment(startDate).utc().startOf("day").add("days", 1).format();
        endDate = endDate && moment(endDate).utc().endOf("day");
        endDate = endDate && moment(endDate).add("days", 1);
        endDate = endDate && moment(endDate).subtract(330, "minute");
        endDate = endDate && moment(endDate).utc().format();
        // endDate =
        //     endDate &&
        //     moment(endDate).utc().endOf("day").add("days", 1).format();
        log.info("startDate filter effect:", startDate, "endDate :", endDate);
        let _dispatchDate =
            filter?.dispatchDate &&
            moment(filter?.dispatchDate).format("DD.MM.yyyy");
        log.info("_dispatchDate filter effect:", _dispatchDate);
        try {
            if (client) {
                const res = await client.query({
                    query: MASTER_DISPATCH_TABLE,
                    variables: {
                        skip: skip,
                        limit: limit,
                        criteria: {
                            dispatchDate: filter?.dispatchDate
                                ? _dispatchDate
                                : null,
                            fileName: filter?.fileName ?? null,
                            status: filter?.status ?? null,
                            startDate:
                                filter?.date &&
                                Array.isArray(filter?.date) &&
                                filter?.date[0] !== ""
                                    ? startDate
                                    : null,
                            endDate:
                                filter?.date &&
                                Array.isArray(filter?.date) &&
                                filter?.date[1] !== ""
                                    ? endDate
                                    : null,
                            download: filter?.download ?? false,
                        },
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.masterDispatchTable)
                    return res?.data?.masterDispatchTable;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getDispatchTable(
        limit: number,
        skip: number,
        filter: any,
        masterId: string
    ) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        log.info("filter :", filter);
        let _date = filter?.date ?? [];
        let startDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[0] : null;
        let endDate =
            _date && Array.isArray(_date) && _date.length > 1 ? _date[1] : null;
        log.info("startDate filter:", startDate, "endDate :", endDate);
        startDate = startDate && moment(startDate).utc().startOf("day");
        startDate = startDate && moment(startDate).add("days", 1);
        startDate = startDate && moment(startDate).subtract(330, "minute");
        startDate = startDate && moment(startDate).utc().format();
        // startDate =
        //     startDate &&
        //     moment(startDate).utc().startOf("day").add("days", 1).format();
        endDate = endDate && moment(endDate).utc().endOf("day");
        endDate = endDate && moment(endDate).add("days", 1);
        endDate = endDate && moment(endDate).subtract(330, "minute");
        endDate = endDate && moment(endDate).utc().format();
        // endDate =
        //     endDate &&
        //     moment(endDate).utc().endOf("day").add("days", 1).format();
        log.info("startDate filter effect:", startDate, "endDate :", endDate);
        let _dispatchDate =
            filter?.dispatchDate &&
            moment(filter?.dispatchDate).format("DD.MM.yyyy");
        log.info("_dispatchDate filter effect:", _dispatchDate);
        try {
            if (client) {
                const res = await client.query({
                    query: DISPATCH_TABLE,
                    variables: {
                        skip: skip,
                        limit: limit,
                        criteria: {
                            masterDispatchId: masterId,
                            batchno: filter?.batchno ?? null,
                            exciseBatchNumber:
                                filter?.exciseBatchNumber ?? null,
                            groupDescription: filter?.groupDescription ?? null,
                            materialGroup: filter?.materialGroup ?? null,
                            materialGroup4: filter?.materialGroup4 ?? null,
                            mfgdate: filter?.mfgdate ?? null,
                            packsize: filter?.packsize ?? null,
                            plant: filter?.plant ?? null,
                            sapCode: filter?.sapCode ?? null,
                            status: filter?.status ?? null,
                            startDate:
                                filter?.date &&
                                Array.isArray(filter?.date) &&
                                filter?.date[0] !== ""
                                    ? startDate
                                    : null,
                            endDate:
                                filter?.date &&
                                Array.isArray(filter?.date) &&
                                filter?.date[1] !== ""
                                    ? endDate
                                    : null,
                            download: filter?.download ?? false,
                        },
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.dispatchTable) return res?.data?.dispatchTable;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    async getDispatchData(masterId: string) {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        log.blue(`in service masterId: ${masterId}`);
        try {
            if (client) {
                const res = await client.query({
                    query: GET_DISPATCH_DATA,
                    variables: {
                        masterDispatchId: masterId,
                    },
                    fetchPolicy: "network-only",
                });
                if (res?.data?.getMasterDispatch)
                    return res?.data?.getMasterDispatch;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
